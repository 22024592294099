<template>
    <div class="apple-play">
        <!-- <img src="@/assets/img/applepaybutton.png" alt="Apple Pay" /> -->
        <!-- <h6>{{$t("paymentMethodsComingSoon")}}</h6> -->
        <!-- <div class="console">

        <span class="errors" v-for="(txt, id) in con" :key="id">{{txt}}<br></span>
      </div> -->
        <apple-pay-button v-if="canPay == true" buttonstyle="black" type="buy" :locale="`${$i18n.locale}-${$t('countryCode')}`"
            @click="onApplePayButtonClicked(amount)"></apple-pay-button>
        <span v-else-if="canPay == false">{{$t("applePayNotSupported")}}</span>
    </div>
</template>
<script>
    const axios = require("axios")

    import { getConfig } from "@/assets/js/Utils"
    export default {
        name: 'ApplePay',
        props: {
            amount: {
                type: String,
                required: true
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                canPay: null,
                con: [],
                merchantIdentifier: 'merchant.com.worldline.dsp.wldd.demo',
                domainName: 'sap.wl.tc',
                displayName: 'Scan And Pay'
            }
        },
        async created() {
            if (window.ApplePaySession) {
                console.log("ApplePay session found")
                var promise = ApplePaySession.canMakePaymentsWithActiveCard(this.merchantIdentifier);
                promise.then((canMakePayments) => {
                        console.log("Test can make payment")
                        if (canMakePayments) {
                            console.log("Can make payment")
                            this.canPay = true
                            // Display Apple Pay button here.
                        } else {
                            this.canPay = false
                            console.log("Can't make payment")
                        }
                    })
                    .catch(e => {
                        console.log(e)
                    });
            } else {
                this.canPay = false
                console.log("ApplePay session not found")
            }
        },
        methods: {
            async onApplePayButtonClicked(amount) {
                if (!ApplePaySession) {
                    this.con.push("No apple pay session")
                    return;
                }

                const request = {
                    "countryCode": this.$t("countryCode"),
                    "currencyCode": "EUR",
                    "merchantCapabilities": [
                        "supports3DS"
                    ],
                    "supportedNetworks": [
                        "visa",
                        "masterCard",
                        "amex",
                        "discover",
                        "cartesBancaires",
                        "electron",
                        "vPay",
                        "maestro"
                    ],
                    "total": {
                        "label": "Scan&Pay",
                        "amount": amount
                    }
                };

                // Create ApplePaySession
                const session = new ApplePaySession(4, request);

                session.begin();

                session.onvalidatemerchant = event => {
                    // Call your own server to request a new merchant session.
                    this.con.push("On validate merchant")
                    var postdata = new URLSearchParams();
                    postdata.append("validationUrl", event.validationURL);
                    postdata.append("domainName", this.domainName);
                    postdata.append("displayName", this.displayName);
                    postdata.append("merchantId", this.merchantIdentifier);
                    postdata.append("useNewCert", true);
                    axios.post(`${getConfig().url_ws}/applepay/get/merchant/validation`, postdata).then(
                        response => {
                            session.completeMerchantValidation(response.data);
                            this.con.push(JSON.stringify(response))
                            this.con.push("Merchant validation done")
                        });

                };

                session.onpaymentmethodselected = event => {
                    // Define ApplePayPaymentMethodUpdate based on the selected payment method.
                    // No updates or errors are needed, pass an empty object.
                    console.log("SelectedPayment method:", event.paymentMethod)
                    const applePayPaymentMethodUpdate = {
                        newLineItems: [],
                        newTotal: {
                            label: "Scan&Pay",
                            amount: amount
                        }
                    }
                    session.completePaymentMethodSelection(applePayPaymentMethodUpdate);
                };

                session.onshippingmethodselected = event => {
                    // Define ApplePayShippingMethodUpdate based on the selected shipping method.
                    // No updates or errors are needed, pass an empty object.
                    const update = {};
                    session.completeShippingMethodSelection(update);
                };

                session.onshippingcontactselected = event => {
                    // Define ApplePayShippingContactUpdate based on the selected shipping contact.
                    const update = {};
                    session.completeShippingContactSelection(update);
                };

                session.onpaymentauthorized = event => {
                    // Define ApplePayPaymentAuthorizationResult
                    const result = {
                        "status": ApplePaySession.STATUS_SUCCESS
                    };
                    session.completePayment(result);
                    this.$router.push('/paymentdone')
                };

                session.oncancel = event => {
                    // Payment cancelled by WebKit
                    console.log("ApplePay session canceled")
                    console.log(event)
                };
            }
        }
    };
</script>
<style scoped>
    .console {
        width: 100%;
        height: 200px;
        overflow: scroll;
    }

    apple-pay-button {
        --apple-pay-button-width: 140px;
        --apple-pay-button-height: 30px;
        --apple-pay-button-border-radius: 5px;
        --apple-pay-button-padding: 5px 0px;
    }

    .apple-play {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .apple-play img {
        opacity: .75;
    }
</style>
