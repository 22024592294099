<template>
    <div class="paymentMethods">
        
        <Steps :steps="processSteps" :nbrProducts="nbrProducts" :active="2" />
        <div class="payment-methods-body">
                <div class="payment-method" v-for = "paymentMethod in paymentMethodsListToShow" :key="paymentMethod.id">
                    <div class="payment-method-name" @click="selectedMethod = paymentMethod.id">
                        <div class="payment-method-img" :style="{backgroundImage: 'url(' + require(`@/assets/img/${paymentMethod.fileName}.png`) +')'}">
                            <div class="color-overlay"></div>
                        </div>
                        <label :for="paymentMethod.id" class="paymentMethodName">{{paymentMethod.methodTitle}}</label>
                        <div class="payment-method-radio">
                            <div class="custom-radio-wrapper">
                                <span :class="{'custom-radio': true, 'checked': selectedMethod  === paymentMethod.id}"></span>
                                <input class="radio" type="radio" name="paymentMethod" v-bind:id="paymentMethod.id" :checked="selectedMethod  === paymentMethod.id"/>
                            </div>
                        </div>
                    </div>
                </div>
            <div class="divider"></div>
            <div class="checkbox-group gts">
                <div class="payment-method-radio">
                    <div class="custom-checkbox-wrapper" @click="termsAgreed = !termsAgreed">
                        <span :class="{'custom-checkbox': true, 'checked': termsAgreed}"></span>
                        <input type="checkbox" v-model="termsAgreed" id="gts" required>
                    </div>
                </div>
                <label for="gts" id="label-gts">{{$t('agreeTerms')}} {{$t('terms')}}</label>
            </div>
        </div>
        <W3CPayment v-if="showW3C" :totalAmount="totalAmount" @close="showW3C = false" :paymentMode="selectedMethodObject.methodName"/>
        <div class="payment-methods-footer">
            <span class="payment-warn" v-if="showPaymentWarn && !termsAgreed">{{$t("paymentWarning")}}</span>
            <ApplePay v-if="termsAgreed && selectedMethodObject.methodName == 'ApplePay'" :amount="totalAmount.toString()" disabled />
            <GooglePay v-else-if="termsAgreed && selectedMethodObject.methodName == 'GooglePay'" :amount="totalAmount.toString()" disabled />
            <FooterComponent v-else inPaymentMethods :disableCheckoutButton="!checkoutEnabled" :showScanBtn="false" @goToPayment="checkout" @goToPaymentError="showPaymentWarn = true"/>
        </div>
    </div>

</template>

<script>
    import {isNotNull} from "@/assets/js/Utils"
    import Steps from "@/components/Food/Steps";
    import SIPS from './PaymentMethods/Sips.vue'
    import Valinawithdraw from './PaymentMethods/Valinawithdraw.vue'
    import GooglePay from './PaymentMethods/GooglePay.vue'
    import ApplePay from './PaymentMethods/ApplePay.vue'
    import Loyalty from './PaymentMethods/Loyalty.vue'
    import W3CPayment from './PaymentMethods/W3CPayment.vue'
    var Checkout = require('@/assets/js/Checkout');
    var Utils = require("@/assets/js/Utils");
    import FooterComponent from "@/components/Food/FooterComponent";

    export default {
        name: 'PaymentMethods',
        components: {
            SIPS,
            Valinawithdraw,
            Loyalty,
            W3CPayment,
            Steps,
            FooterComponent,
            GooglePay,
            ApplePay
        },
        data: function() {
            return{
                paymentMethodsList: [],
                fromRoute: null,
                alertContent : "",
                totalAmount: 0,
                customerBasket: null,
                currentLoyaltyPoints: 0,
                useLoyalty: "false",
                selectedMethod: 1,
                termsAgreed: false,
                processSteps: Utils.getSteps(),
                base : localStorage.getItem('DKC_base'),
                showW3C: false,
                page: {
                    title: this.$t("pages.payment.title"),
                    description: null,
                    showBackBtn: true,
                    showFilterBtn: false,
                    showSearch: false
                },
                showPaymentWarn: false
            }
        },
        beforeRouteEnter (to, from, next) {
            next(vm => {
                vm.fromRoute = from;
            })
        },
        created(){
            console.log(this)
            this.showPaymentWarn = false
            this.customerBasket = this.$parent.basket;
            this.totalAmount = this.customerBasket.totalAmount;
            this.paymentMethodsList = Checkout.getPaymentMethodsList(this);
            let loyaltyBalance = localStorage.getItem("IEC_LOYALTY")
            let loyaltyToUse = localStorage.getItem("IEC_LOYALTY_TO_USE")
            if(loyaltyBalance != null){
                this.customerBasket.loyaltyBalance = (parseFloat(loyaltyBalance) - parseFloat(loyaltyToUse)) + (parseFloat(this.totalAmount) - parseFloat(loyaltyToUse)) * 0.20
                this.totalAmount = this.totalAmount - parseFloat(loyaltyToUse)
            }else{
                this.customerBasket.loyaltyBalance = null
            }
            localStorage.setItem("IEC_ticket", JSON.stringify(this.customerBasket));
            localStorage.setItem("IEC_ticketAmount", this.totalAmount);
            if (this.$route.params.error) {
                this.alertContent = this.$t('paymentError')
            }
            
            if(isNotNull(this.$parent.customer) && isNotNull(this.$parent.customer.loyalty)){
                this.currentLoyaltyPoints = this.$parent.customer.loyalty.loyaltyPoint;
            }else{
                this.currentLoyaltyPoints = 0;
            }
        },
        methods: {
            handleBack (fallback) {
                if (!this.fromRoute.name) {
                    this.$router.push(fallback);
                } else {
                    this.$router.push("/basket");
                }
            },
            paymentCanceled(){
                this.alertContent = this.$t('paymentError')
            },
            formatPrice(price) {
                return Utils.formatPrice(price);
            },
            showPaymentMethod(paymentMethod){
                if(!paymentMethod.enable){
                    return false
                }
                if(paymentMethod.fileName == "Loyalty" && (this.totalAmount >= this.currentLoyaltyPoints || this.useLoyalty == "true")){
                    return false;
                }
                return true;
            },
            methodPaymentChoosen(callback){
                this.$parent.showLoader = true;
                var paymentMethodChoosen = this.paymentMethodChoosen;
                if(isNotNull(paymentMethodChoosen)){
                    var parameters = {};
                    parameters[paymentMethodChoosen] = 1;
                    callback();
                }
            },
            checkout(){
                if(this.checkoutEnabled){
                    this.paymentMethodChoosen = this.selectedMethod
                    if(this.selectedMethod === 1){
                        this.$router.push("/sips")
                    }else if(this.selectedMethod === 2 || this.selectedMethod === 4){
                        this.$router.push('/scan?inPayment=true');
                    }else if(this.selectedMethod === 3 || this.selectedMethod === 5){
                        this.showW3C = true;
                    }
                }
            }
        },
        computed:{
            paymentMethodsListToShow(){
                return this.paymentMethodsList.filter(method => this.showPaymentMethod(method))
            },
            nbrProducts(){
                return this.customerBasket.basketProducts.reduce((acc, product) => {return acc + product.qty}, 0)
            },
            checkoutEnabled(){
                return this.termsAgreed
            },
            selectedMethodObject(){
                return this.paymentMethodsListToShow.filter(method => this.selectedMethod == method.id)[0]
            }
        },
        watch:{
            useLoyalty: function () {
                if(this.useLoyalty == "true"){
                    localStorage.setItem("DKC_loyaltyToUse", this.currentLoyaltyPoints);
                    localStorage.setItem("IEC_ticketAmount", this.totalAmount - this.currentLoyaltyPoints);
                    this.totalAmount = this.totalAmount - this.currentLoyaltyPoints;
                }else{
                    localStorage.removeItem("DKC_loyaltyToUse");
                    localStorage.setItem("IEC_ticketAmount", this.totalAmount);
                    this.totalAmount = this.customerBasket.totalAmount;
                }
            }
        }
    }
</script>
<style scoped>
    .payment-methods-body{
        display: flex;
        flex-direction: column;
        align-items: stretch;
        padding-bottom: 150px;
    }
    .payment-methods-footer{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .payment-method-img{
        width: 48px;
        height: 48px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }
    .payment-warn{
        width: 80%;
        font-style: italic;
        font-size: .85rem;
        text-align: center;
        margin: 16px 0;
        color: #dc3545;
    }
    .color-overlay{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgb(var(--hot-food-color));
        mix-blend-mode: color;
    }
</style>